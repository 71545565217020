/**
 * The names of all the message coming from sales pilot to the loader and the host's website
 */
export const MessagesFromSP = {
  CHANGE_IFRAME_SIZE: 'SP_CHANGE_IFRAME_SIZE',
  IFRAME_LOADED: 'SP_IFRAME_LOADED',
  USER_LOGGED_IN: 'SP_USER_LOGGED_IN',
  USER_SIGNED_UP: 'SP_USER_SIGNED_UP',
  USER_LOGGED_OUT: 'SP_USER_LOGGED_OUT',
  PENDING_USER_CREATED: 'SP_PENDING_USER_CREATED',
  NAVIGATE_IN_HOST: 'SP_NAVIGATE_IN_HOST',
  MULTIPLE_PARTS_PRICE_RESPONSE: 'SP_MULTIPLE_PARTS_PRICE_RESPONSE',
};

/**
 * The names of all the message expected sales pilot to receive the loader and the host's website
 */
export const MessagesToSP = {
  OPEN_CHAT_PAGE: 'SP_OPEN_CHAT_PAGE',
  OPEN_SEARCH_PAGE: 'SP_OPEN_SEARCH_PAGE',
  COLLAPSE: 'SP_COLLAPSE',
  SHOW: 'SP_SHOW',
  HIDE: 'SP_HIDE',
  RELOAD: 'SP_RELOAD',
  ADD_TO_CART: 'SP_ADD_TO_CART',
  BUY_NOW: 'SP_BUY_NOW',
  REQUEST_MULTIPLE_PARTS_PRICES: 'SP_REQUEST_MULTIPLE_PARTS_PRICE',
  CREATE_QUOTE: 'SP_CREATE_QUOTE',
  NOTIFY_HOST_WAS_RESIZED: 'SP_NOTIFY_HOST_WAS_RESIZED',
  NOTIFY_USER_HAS_LOGGED_IN: 'SP_NOTIFY_USER_HAS_LOGGED_IN',
  NOTIFY_USER_HAS_SIGNED_UP: 'SP_NOTIFY_USER_HAS_SIGNED_UP',
  NOTIFY_USER_HAS_LOGGED_OUT: 'SP_NOTIFY_USER_HAS_LOGGED_OUT',
  NOTIFY_PENDING_USER_WAS_CREATED: 'SP_NOTIFY_PENDING_USER_WAS_CREATED',
};
