import { range } from 'lodash';
import { Box, Stack, Typography } from '@mui/material';
import { useManufacturersAutocompleteRequest } from '@qb/frontend/components/OmniSearch/_hooks/useManufacturersAutocompleteRequest';
import { TenantAliasProps } from '@qb/frontend/types/tenantAlias.types';
import { isNullableOrEmptyArray } from '@/shared/types/util/TypeGuards';
import { ManufacturerResult, ManufacturerResultSkeleton } from './ManufacturerResult';
type ManufacturerQueryProps = TenantAliasProps & {
  query: string;
};
export const ManufacturerQuery = ({
  query,
  tenantAlias
}: ManufacturerQueryProps) => {
  const {
    areManufacturersLoading,
    manufacturers
  } = useManufacturersAutocompleteRequest(query, tenantAlias);
  if (!areManufacturersLoading && isNullableOrEmptyArray(manufacturers)) {
    return null;
  }
  return <Stack direction="column" spacing={1} data-sentry-element="Stack" data-sentry-component="ManufacturerQuery" data-sentry-source-file="ManufacurerQuery.tsx">
      <Typography variant="h6" pb={1} color="text.secondary" data-sentry-element="Typography" data-sentry-source-file="ManufacurerQuery.tsx">
        Manufacturers
      </Typography>
      <Box pl={2} mb={2} data-sentry-element="Box" data-sentry-source-file="ManufacurerQuery.tsx">
        {areManufacturersLoading ? range(2).map((_, index) => <ManufacturerResultSkeleton key={index} />) : manufacturers.map(manufacturer => <Box key={manufacturer.id} mb={2}>
                <ManufacturerResult manufacturer={manufacturer} />
              </Box>)}
      </Box>
    </Stack>;
};