import { API_HOST } from '@qb/app-web/WebConstants';
import { isDevEnv, isTestEnv } from '@qb/frontend/utils/env.utils';
import { getHostFromWindow } from '@qb/frontend/utils/hosts/getHostFromWindow';
import {
  getConfigFromTenantAlias,
  TenantAlias,
  validateTenantAlias,
} from '@/shared/tenants-alias-map';

export const API_PREFIXES = {
  WEB: '/api/web',
  REST: '/api/rest',
  SPS: '/api/sps',
  SPA: '/api/spa',
  SPW: '/api/widget',
  internalAdmin: '/internalAdmin',
} as const;

type APIType = keyof typeof API_PREFIXES;
/**
 * Get the API URL from host and isDemo flag.
 */
export const getAPIUrlFromHost = (host: string, isDemo: boolean) => {
  // Allows overriding the API host for development or test envs, for example with staging api or test backend api
  if ((isDevEnv || isTestEnv) && process.env.API_HOST_OVERRIDE) {
    return process.env.API_HOST_OVERRIDE;
  }
  const baseDomain = host.split('.').slice(-2).join('.');
  const cleanBaseDomain = baseDomain.split(':')[0];

  let apiSubdomain = '';
  if (isDemo && isDevEnv) {
    apiSubdomain = 'sandbox';
  } else if (isDemo) {
    apiSubdomain = 'sandbox.';
  }
  const port = isDevEnv ? ':1337' : '';

  return `https://${apiSubdomain}api.${cleanBaseDomain}${port}`;
};

/**
 * Get the API URL for the given tenant alias and API type.
 */
const getApiURLForTenant = (
  apiType: APIType,
  tenantAlias: TenantAlias,
  host: string,
) => {
  if (!host) {
    throw new Error('Host is required to get the API prefix');
  }

  const config = getConfigFromTenantAlias(tenantAlias);
  const apiHost = getAPIUrlFromHost(host, config.isDemo);

  let prefix = API_PREFIXES[apiType];
  if (apiType === 'SPS' || apiType === 'SPA') {
    prefix += `/${config.tenantID}`;
  }

  return apiHost + prefix;
};

// Gets the full URL for the web API (tenant specific) using the tenant alias and host
export const getWebApiURLForTenant = (
  tenantAlias: TenantAlias,
  host: string,
) => {
  return getApiURLForTenant('WEB', tenantAlias, host);
};

// Gets the full URL for the SPA API (tenant specific) using the tenant alias and host
export const getSPAApiURLForTenant = (tenantAlias: TenantAlias, host: string) =>
  getApiURLForTenant('SPA', tenantAlias, host);

// Gets the full URL for the REST API (tenant specific) using the tenant alias and host
export const getRestApiURLforTenant = (
  tenantAlias: TenantAlias,
  host: string,
) => {
  return getApiURLForTenant('REST', tenantAlias, host);
};

// Gets the full URL for the SPS API (tenant specific) using the tenant alias and host
export const getSPSApiURLForTenant = (
  tenantAlias: TenantAlias,
  host: string,
) => {
  return getApiURLForTenant('SPS', tenantAlias, host);
};

// Gets the full URL for the SPW API (tenant specific) using the tenant alias and host
export const getSPWApiURLForTenant = () => {
  const tenantAlias = new URLSearchParams(window.location.search).get(
    'tenantAlias',
  );

  const validatedTenantAlias = validateTenantAlias(tenantAlias);
  const host = getHostFromWindow();

  return getApiURLForTenant('SPW', validatedTenantAlias, host);
};

// Gets the full URL for the web API using the env variable (not tenant specific).
export const getWebApiURL = () => {
  return API_HOST + API_PREFIXES.WEB;
};

// Gets the base URL for the web API using the env variable (not tenant specific).
export const getWebApiBaseURL = () => {
  return API_HOST;
};

// Gets the full URL for the rest API using the env variable (not tenant specific)
export const getRestApiURL = () => {
  return API_HOST + API_PREFIXES.REST;
};

// Gets the full URL for the internal admin API using the env variable (not tenant specific)
export const getInternalAdminApiPrefix = () => {
  return API_HOST + API_PREFIXES.internalAdmin;
};
