'use client';

import { useRef, useState, ChangeEvent, useEffect } from 'react';
import { Box, BoxProps, SxProps } from '@mui/material';
import { EVENT_ROUTE_CHANGE_START, EVENT_HIDE_OMNISEARCH } from '@qb/frontend/components/OmniSearch/omniSearchConstants';
import { useIsUnderBreakpoint } from '@qb/frontend/hooks/mediaQueries';
import { useDebouncedQuery } from '@qb/frontend/hooks/useDebouncedQuery';
import { TenantAliasProps } from '@qb/frontend/types/tenantAlias.types';
import { EventEmitter } from '@qb/frontend/utils/EventEmitter';
import { OmniSearchDesktopPopup } from './_components/OmniSearchDesktopPopup/OmniSearchDesktopPopup';
import { OmniSearchInput, OmniSearchInputG4AEventsProps } from './_components/OmniSearchInput';
import { OmniSearchMobileDrawer } from './_components/OmniSearchMobileDrawer/OmniSearchMobileDrawer';
import { useIsUserTyping } from './_hooks/useIsUserTyping';
type HeroOmniSearchProps = TenantAliasProps & OmniSearchInputG4AEventsProps & {
  inputSx?: SxProps;
  isPlaceholderAnimationEnabled?: boolean;
  magicIconSx: SxProps;
  width?: BoxProps['width'];
  handlerRedirect: (href: string) => void;
};
export const HeroOmniSearch = ({
  searchInputName,
  inputSx,
  isPlaceholderAnimationEnabled,
  magicIconSx,
  width = {
    xs: '100%',
    sm: 600,
    lg: 800
  },
  handlerRedirect,
  tenantAlias
}: HeroOmniSearchProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const {
    query,
    setQuery,
    debouncedQuery
  } = useDebouncedQuery('', 300);
  const isUserTyping = useIsUserTyping(query);
  const ref = useRef<HTMLElement | null>(null);
  useEffect(() => {
    const hideOmniSearch = () => setIsOpen(false);
    EventEmitter.subscribe(EVENT_ROUTE_CHANGE_START, hideOmniSearch);
    return () => {
      EventEmitter.unsubscribe(EVENT_ROUTE_CHANGE_START, hideOmniSearch);
    };
  }, [isOpen, setIsOpen]);
  const shouldShowMobile = useIsUnderBreakpoint('md');
  if (shouldShowMobile) {
    return <>
        <Box width={width}>
          <OmniSearchInput searchInputName="" value={query} onClick={() => setIsOpen(true)} sx={inputSx} magicIconSx={magicIconSx} tenantAlias={tenantAlias} handlerRedirect={handlerRedirect} isPlaceholderAnimationEnabled={isPlaceholderAnimationEnabled} />
        </Box>
        <OmniSearchMobileDrawer searchInputName={searchInputName} isVisible={isOpen} setIsVisible={setIsOpen} tenantAlias={tenantAlias} handlerRedirect={handlerRedirect} />
      </>;
  }
  const onFocus = () => {
    setIsOpen(true);
    EventEmitter.publish(EVENT_HIDE_OMNISEARCH);
    const element = ref?.current;
    if (element) {
      const offset = 190;
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.scrollY - offset;
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
  };
  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
  };
  return <>
      <Box ref={ref} width={width} data-sentry-element="Box" data-sentry-source-file="HeroOmniSearch.tsx">
        <OmniSearchInput searchInputName={searchInputName} value={query} onFocus={onFocus} onChange={onChange} sx={inputSx} magicIconSx={magicIconSx} tenantAlias={tenantAlias} handlerRedirect={handlerRedirect} isPlaceholderAnimationEnabled={isPlaceholderAnimationEnabled} data-sentry-element="OmniSearchInput" data-sentry-source-file="HeroOmniSearch.tsx" />
      </Box>
      <OmniSearchDesktopPopup query={debouncedQuery} isUserTyping={isUserTyping} ref={ref} isOpen={isOpen} setIsOpen={setIsOpen} marginTop={1} tenantAlias={tenantAlias} data-sentry-element="OmniSearchDesktopPopup" data-sentry-source-file="HeroOmniSearch.tsx" />
    </>;
};