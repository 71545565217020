import { API_PREFIXES } from '@qb/frontend/utils/hosts/apiHost';
import { API_HOST } from '../../apps/web/WebConstants';

/**
 * @deprecated  use functions in apiHost.ts
 */
export const WebAPIFullURL = API_HOST + API_PREFIXES.WEB;
/**
 * @deprecated  use functions in apiHost.ts
 */
export const RestAPIFullURL = API_HOST + API_PREFIXES.REST;
/**
 * @deprecated  use functions in apiHost.ts
 */
export const InternalAdminFullURL = API_HOST + API_PREFIXES.internalAdmin;

export const UNAUTHORIZED_ERROR_CODE = 401;
export const FORBIDDEN_ERROR_CODE = 403;
export const NOT_FOUND_ERROR_CODE = 404;

export const BAD_REQUEST_EVENT = 'BAD_REQUEST_EVENT';

export const SERVER_ERROR_MESSAGE =
  'Oops, something is not working… We have been notified. Please try again soon 🥲';
