'use client';

import { useCallback } from 'react';
import { Card, CardActionArea, Skeleton, Stack } from '@mui/material';
import { EcommerceItem } from '@qb/frontend/analytics/ga4Types';
import { TenantAlias } from '@/shared/tenants-alias-map';
import { PartRelevantPartsDataResponse } from '@/shared/types/controllers/sps/SPSPartControllerTypes';
import { generatePartURL } from '@/shared/utils/URLs';
import { PartCardHorizontalInner } from './components/PartCardHorizontalInner';
type PartCardHorizontalProps = {
  tenantAlias: TenantAlias;
  part: PartRelevantPartsDataResponse['parts'][0];
  onClick?: () => void;
  ga4data: {
    itemListName: EcommerceItem['item_list_name'];
    itemIndex: EcommerceItem['index'];
  };
};
export const PartCardHorizontal = ({
  tenantAlias,
  part,
  onClick
}: PartCardHorizontalProps) => {
  const href = generatePartURL(part.manufacturer.name, part.partNumber, part.id);
  const onPartView = useCallback(() => {
    if (onClick) {
      onClick();
    }
  }, [onClick]);
  return <Card sx={{
    borderWidth: '2px',
    borderColor: 'primary.12p',
    mb: {
      xs: 1,
      borderRadius: 0
    }
  }} data-sentry-element="Card" data-sentry-component="PartCardHorizontal" data-sentry-source-file="PartCardHorizontal.tsx">
      <CardActionArea onClick={onPartView} href={href} data-sentry-element="CardActionArea" data-sentry-source-file="PartCardHorizontal.tsx">
        <PartCardHorizontalInner part={part} tenantAlias={tenantAlias} data-sentry-element="PartCardHorizontalInner" data-sentry-source-file="PartCardHorizontal.tsx" />
      </CardActionArea>
    </Card>;
};
export const PartCardHorizontalSkeleton = () => <Card data-sentry-element="Card" data-sentry-component="PartCardHorizontalSkeleton" data-sentry-source-file="PartCardHorizontal.tsx">
    <Stack direction="row" spacing={2} width="100%" sx={{
    py: 1,
    px: 1.5,
    minWidth: 200
  }} data-sentry-element="Stack" data-sentry-source-file="PartCardHorizontal.tsx">
      <Skeleton variant="rectangular" width={50} height={50} sx={{
      flexShrink: 0
    }} data-sentry-element="Skeleton" data-sentry-source-file="PartCardHorizontal.tsx" />
      <Stack spacing={1} width="100%" data-sentry-element="Stack" data-sentry-source-file="PartCardHorizontal.tsx">
        <Skeleton width={100} data-sentry-element="Skeleton" data-sentry-source-file="PartCardHorizontal.tsx" />
        <Skeleton data-sentry-element="Skeleton" data-sentry-source-file="PartCardHorizontal.tsx" />
        <Skeleton width={60} data-sentry-element="Skeleton" data-sentry-source-file="PartCardHorizontal.tsx" />
      </Stack>
    </Stack>
  </Card>;