import { useEffect, useState } from 'react';

type UseTypewriterEffectParams = {
  text: string;
  isEnabled?: boolean;
  typingSpeedMs?: number;
  delayBeforeRestartMs?: number;
};

export const useTypewriterEffect = ({
  text,
  isEnabled,
  typingSpeedMs = 100,
  delayBeforeRestartMs = 2000,
}: UseTypewriterEffectParams) => {
  const [textToDisplay, setTextToDisplay] = useState('');

  useEffect(() => {
    if (!isEnabled) {
      return setTextToDisplay(text);
    }
    setTextToDisplay('');
    let index = 0;
    const animateText = () => {
      setTextToDisplay(text.substring(0, index));
      index += 1;

      if (index > text.length) {
        clearInterval(intervalId);
      }
    };

    const restart = () => {
      index = 0;
      intervalId = setInterval(animateText, typingSpeedMs);
    };

    let intervalId = setInterval(animateText, typingSpeedMs);
    const restartIntervalId = setInterval(
      restart,
      text.length * typingSpeedMs + delayBeforeRestartMs,
    );

    return () => {
      clearInterval(intervalId);
      clearInterval(restartIntervalId);
    };
  }, [text, isEnabled, typingSpeedMs, delayBeforeRestartMs]);

  return { textToDisplay };
};
