import { AxiosRequestConfig, isAxiosError } from 'axios';
import { axiosInstance } from './axiosInstance';
import { SERVER_ERROR_MESSAGE } from './constants';
import {
  ApiBusinessLogicError,
  isApiBusinessLogicError,
  customAPIErrorMessages,
} from './utils';

export type SocketError = { message: ApiBusinessLogicError };

export const socketErrorHandler = (
  e: SocketError,
  errorMessage: string = SERVER_ERROR_MESSAGE,
) => {
  if (isApiBusinessLogicError(e?.message)) {
    throw {
      ...e?.message,
      message: customAPIErrorMessages[e?.message.code] ?? e?.message.message,
    };
  }
  // TODO: on user-initiated requests, then show an error, but not for background requests
  // toast.error(errorMessage);
  console.error(errorMessage, e);

  throw e;
};

const axiosErrorHandler = (
  e: unknown,
  errorMessage: string = SERVER_ERROR_MESSAGE,
) => {
  if (
    isAxiosError<ApiBusinessLogicError>(e) &&
    isApiBusinessLogicError(e.response?.data) &&
    e.response?.data?.code
  ) {
    const message =
      customAPIErrorMessages[e.response?.data?.code] ??
      e.response?.data?.message;

    throw {
      ...e.response?.data,
      message,
    };
  }

  // TODO: on user-initiated requests, then show an error, but not for background requests
  // toast.error(errorMessage);
  console.error(errorMessage, e);
  throw e;
};

/** Don't use in App server, it will error */
export const uploadRequestPromise = async <
  Response,
  Request extends Record<string, string | boolean | null | number | Blob>,
>(
  url: string,
  payload: Request,
  errorMessage?: string,
): Promise<Response> => {
  const form = new FormData();
  Object.keys(payload).forEach((key) => {
    if (
      !(payload[key] instanceof Blob) &&
      payload[key] !== undefined &&
      payload[key] !== null
    ) {
      // @ts-expect-error - already checked null above
      form.append(key, payload[key].toString());
    }
  });
  // Put any file parameters after text parameters. See bolded text at https://sailsjs.com/documentation/reference/request-req/req-file
  Object.keys(payload).forEach((key) => {
    if (payload[key] instanceof Blob && payload[key] !== undefined) {
      form.append(key, payload[key] as Blob);
    }
  });
  return axiosInstance
    .postForm<Response>(url, form)
    .then((res) => res.data)
    .catch((e) => axiosErrorHandler(e, errorMessage));
};

/** Don't use in App server, it will error */
export const postRequestPromise = async <Response, Request>(
  url: string,
  payload?: Request,
  errorMessage?: string,
): Promise<Response> => {
  return axiosInstance
    .post<Response>(url, payload)
    .then((res) => res.data)
    .catch((e) => axiosErrorHandler(e, errorMessage));
};

/** Don't use in App server, it will error */
export const getRequestPromise = async <Response>(
  url: string,
  config: AxiosRequestConfig = {},
  errorMessage?: string,
  errorHandler?: (e: unknown) => Response,
): Promise<Response> => {
  return axiosInstance
    .get<Response>(url, config)
    .then((res) => res.data)
    .catch(errorHandler)
    .catch((e) => axiosErrorHandler(e, errorMessage));
};

/** Don't use in App server, it will error */
export const deleteRequestPromise = async <Response>(
  url: string,
  config: AxiosRequestConfig = {},
  errorMessage?: string,
): Promise<Response> => {
  return axiosInstance
    .delete<Response>(url, config)
    .then((res) => res.data)
    .catch((e) => axiosErrorHandler(e, errorMessage));
};
