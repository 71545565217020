// CSS-in-JS clone of 'slick-carousel/slick/slick-theme.css':

export const slickThemeStyle = {
  '.slick-prev,.slick-next': {
    fontSize: '0',
    lineHeight: 0,
    position: 'absolute',
    top: '50%',
    display: 'block',
    width: '20px',
    height: '20px',
    padding: '0',
    WebkitTransform: 'translate(0, -50%)',
    msTransform: 'translate(0, -50%)',
    transform: 'translate(0, -50%)',
    cursor: 'pointer',
    color: 'transparent',
    border: 'none',
    outline: 'none',
    background: 'transparent',
  },
  '.slick-prev:hover,.slick-prev:focus,.slick-next:hover,.slick-next:focus': {
    color: 'transparent',
    outline: 'none',
    background: 'transparent',
  },
  '.slick-prev:hover:before,.slick-prev:focus:before,.slick-next:hover:before,.slick-next:focus:before':
    {
      opacity: 1,
    },
  '.slick-prev.slick-disabled:before,.slick-next.slick-disabled:before': {
    opacity: 0.25,
  },
  '.slick-prev:before,.slick-next:before': {
    fontFamily: "'slick'",
    fontSize: '20px',
    lineHeight: 1,
    opacity: 0.75,
    color: 'white',
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
  },
  '.slick-prev': { left: '-25px' },
  "[dir='rtl'] .slick-prev": { right: '-25px', left: 'auto' },
  '.slick-prev:before': { content: "'←'" },
  "[dir='rtl'] .slick-prev:before": { content: "'→'" },
  '.slick-next': { right: '-25px' },
  "[dir='rtl'] .slick-next": { right: 'auto', left: '-25px' },
  '.slick-next:before': { content: "'→'" },
  "[dir='rtl'] .slick-next:before": { content: "'←'" },
  '.slick-dotted.slick-slider': { marginBottom: '30px' },
  '.slick-dots': {
    position: 'absolute',
    bottom: '-25px',
    display: 'block',
    width: '100%',
    padding: '0',
    margin: '0',
    listStyle: 'none',
    textAlign: 'center',
  },
  '.slick-dots li': {
    position: 'relative',
    display: 'inline-block',
    width: '20px',
    height: '20px',
    margin: '0 5px',
    padding: '0',
    cursor: 'pointer',
  },
  '.slick-dots li button': {
    fontSize: '0',
    lineHeight: 0,
    display: 'block',
    width: '20px',
    height: '20px',
    padding: '5px',
    cursor: 'pointer',
    color: 'transparent',
    border: '0',
    outline: 'none',
    background: 'transparent',
  },
  '.slick-dots li button:hover,.slick-dots li button:focus': {
    outline: 'none',
  },
  '.slick-dots li button:hover:before,.slick-dots li button:focus:before': {
    opacity: 1,
  },
  '.slick-dots li button:before': {
    fontFamily: "'slick'",
    fontSize: '6px',
    lineHeight: '20px',
    position: 'absolute',
    top: '0',
    left: '0',
    width: '20px',
    height: '20px',
    content: "'•'",
    textAlign: 'center',
    opacity: 0.25,
    color: 'black',
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
  },
  '.slick-dots li.slick-active button:before': {
    opacity: 0.75,
    color: 'black',
  },
};
