import { isBrowser } from '@qb/frontend/utils/env.utils';

// Should be used only on client
export const getHostFromWindow = () => {
  const host = isBrowser && window.location.hostname;

  if (!host) {
    throw new Error('Could not get host from window');
  }

  return host;
};
