'use client';

import { range } from 'lodash';
import { Box, List, ListItem, ListSubheader, Skeleton, Stack, Typography } from '@mui/material';
import { PartCategoryResult } from '@qb/frontend/components/OmniSearch/_components/OmniSearchDesktopPopup/components/Categories/PartCategoryResult';
import { ManufacturerResult } from '@qb/frontend/components/OmniSearch/_components/OmniSearchDesktopPopup/components/Manufacturers/ManufacturerResult';
import { ViewAllCategoriesButton } from '@qb/frontend/components/OmniSearch/_components/ViewAllCategoriesButton';
import { ViewAllPartsButton } from '@qb/frontend/components/OmniSearch/_components/ViewAllPartsButton';
import { useManufacturersAutocompleteRequest } from '@qb/frontend/components/OmniSearch/_hooks/useManufacturersAutocompleteRequest';
import { usePartCategoriesAutocompleteRequest } from '@qb/frontend/components/OmniSearch/_hooks/usePartCategoriesAutocompleteRequest';
import { usePartsAutocompleteRequest } from '@qb/frontend/components/OmniSearch/_hooks/usePartsAutocompleteRequest';
import { PartListItemButton } from '@qb/frontend/components/PartCards/PartListItemButton';
import { TenantAliasProps } from '@qb/frontend/types/tenantAlias.types';
import { clampLinesSx } from '@qb/frontend/utils/clampLinesSx';
import { isNonEmptyArray } from '@/shared/types/util/TypeGuards';
type QueryResultsProps = TenantAliasProps & {
  query: string;
  onResultClick: () => void;
};
export const Query = ({
  query,
  onResultClick,
  tenantAlias
}: QueryResultsProps) => {
  const {
    arePartsLoading,
    parts
  } = usePartsAutocompleteRequest(query, tenantAlias);
  const {
    arePartCategoriesLoading,
    partCategories
  } = usePartCategoriesAutocompleteRequest(query, tenantAlias);
  const {
    areManufacturersLoading,
    manufacturers
  } = useManufacturersAutocompleteRequest(query, tenantAlias);
  const isLoading = arePartsLoading || arePartCategoriesLoading || areManufacturersLoading;
  if (isLoading) {
    return <List subheader={<ListSubheader>Loading results for: {query}</ListSubheader>}>
        {range(3).map(index => <ListItem key={index}>
            <ResultSkeleton />
          </ListItem>)}
      </List>;
  }
  return <>
      <List subheader={<ListSubheader>Parts</ListSubheader>} data-sentry-element="List" data-sentry-source-file="Query.tsx">
        {isNonEmptyArray(parts) ? parts.map(part => <PartListItemButton tenantAlias={tenantAlias} part={part} key={part.id} onClick={onResultClick} />) : <ListItem sx={{
        flexWrap: 'wrap'
      }}>
            <Typography variant="bodySmall" width="100%">
              No parts found matching your search <strong>{query}</strong>
            </Typography>
          </ListItem>}
      </List>
      <Box sx={{
      mx: 2
    }} data-sentry-element="Box" data-sentry-source-file="Query.tsx">
        <ViewAllPartsButton query={query} onClick={onResultClick} data-sentry-element="ViewAllPartsButton" data-sentry-source-file="Query.tsx" />
      </Box>
      <Box sx={{
      my: 1
    }} data-sentry-element="Box" data-sentry-source-file="Query.tsx" />
      <List subheader={<Stack direction="row" justifyContent="space-between" alignItems="center" px={2} mb={1}>
            <Typography variant="h6" color="text.secondary">
              Categories
            </Typography>
            <ViewAllCategoriesButton content="View all" textDecoration onClick={onResultClick} />
          </Stack>} data-sentry-element="List" data-sentry-source-file="Query.tsx">
        {isNonEmptyArray(partCategories) ? partCategories.map(partCategory => <ListItem key={partCategory.id}>
              <PartCategoryResult tenantAlias={tenantAlias} partCategory={partCategory} onClick={onResultClick} />
            </ListItem>) : <ListItem sx={{
        flexWrap: 'wrap'
      }}>
            <Typography variant="bodySmall" width="100%">
              No categories found matching your search <strong>{query}</strong>
            </Typography>
          </ListItem>}
      </List>
      <Box sx={{
      my: 1
    }} data-sentry-element="Box" data-sentry-source-file="Query.tsx" />
      <List subheader={<ListSubheader>
            <Typography variant="h6" color="text.secondary" mb={1}>
              Manufacturers
            </Typography>
          </ListSubheader>} data-sentry-element="List" data-sentry-source-file="Query.tsx">
        {isNonEmptyArray(manufacturers) ? manufacturers.map(manufacturer => <ListItem key={manufacturer.id}>
              <ManufacturerResult manufacturer={manufacturer} />
            </ListItem>) : <ListItem sx={{
        flexWrap: 'wrap'
      }}>
            <Typography variant="bodySmall" width="100%">
              No manufacturers found matching your search{' '}
              <strong>{query}</strong>
            </Typography>
          </ListItem>}
      </List>
    </>;
};
const ResultSkeleton = () => <Stack direction="row" alignItems="center" flexGrow="1" data-sentry-element="Stack" data-sentry-component="ResultSkeleton" data-sentry-source-file="Query.tsx">
    <Skeleton width={48} height={48} data-sentry-element="Skeleton" data-sentry-source-file="Query.tsx" />
    <Stack sx={{
    flexDirection: 'column',
    ml: 3.5,
    width: '100%'
  }} data-sentry-element="Stack" data-sentry-source-file="Query.tsx">
      <Typography variant="h5" mb={1} data-sentry-element="Typography" data-sentry-source-file="Query.tsx">
        <Skeleton data-sentry-element="Skeleton" data-sentry-source-file="Query.tsx" />
      </Typography>
      <Typography variant="bodyMedium" sx={clampLinesSx(2)} data-sentry-element="Typography" data-sentry-source-file="Query.tsx">
        <Skeleton data-sentry-element="Skeleton" data-sentry-source-file="Query.tsx" />
      </Typography>
    </Stack>
  </Stack>;