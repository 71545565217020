'use client';

import { createPortal } from 'react-dom';
import { Backdrop } from '@mui/material';
type OmniSearchBackdropProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};
export const OmniSearchBackdrop = ({
  isOpen,
  setIsOpen
}: OmniSearchBackdropProps) => {
  /** Render the backdrop in the root of the Next app to avoid z-index problems */
  return createPortal(<Backdrop sx={{
    top: 0,
    zIndex: 'var(--qb-omni-search-z-index, var(--mui-zIndex-appBar))'
  }} open={isOpen} onClick={() => setIsOpen(false)} />, (window?.document?.querySelector('body') as HTMLElement));
};