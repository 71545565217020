import { useEffect, useState } from 'react';
import { useDebounce } from 'react-use';

export const useDebouncedQuery = (initialState = '', delay = 300) => {
  const [query, setQuery] = useState(initialState);
  const [debouncedQuery, setDebouncedQuery] = useState('');

  useDebounce(
    () => {
      const trimmedQuery = query.trim();
      if (trimmedQuery) {
        setDebouncedQuery(trimmedQuery);
      }
    },
    delay,
    [query],
  );

  useEffect(() => {
    if (query === '') {
      setDebouncedQuery(query);
    }
  }, [query]);

  return { query, setQuery, debouncedQuery };
};
