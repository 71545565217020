/**
 * This file is in .js until we can import .ts files into the old messenger app.
 * or completely separate the two applications.
 */
import * as Sentry from '@sentry/react';
import axios, { isCancel, AxiosError } from 'axios';
import { EventEmitter } from '@qb/frontend/utils/EventEmitter';
import { isServer } from '@qb/frontend/utils/env.utils';
import { API_HOST } from '../../apps/web/WebConstants';
import {
  UNAUTHORIZED_ERROR_CODE,
  FORBIDDEN_ERROR_CODE,
  NOT_FOUND_ERROR_CODE,
  BAD_REQUEST_EVENT,
} from './constants';
import { isApiBusinessLogicError } from './utils';

export const axiosInstance = axios.create({
  withCredentials: true,
});

const errorHandler = (e: AxiosError) => {
  const isCancelledRequest = isCancel(e);

  if (!isApiBusinessLogicError(e?.response?.data) && !isCancelledRequest) {
    console.error(e, 'axios.interceptors.errorHandler');
    // Log only the actual unexpected errors, not the business logic errors.
    Sentry.captureException(e, (scope) => {
      scope.setExtra('axios.response', (e as AxiosError)?.response);
      scope.setExtra('axios.request', (e as AxiosError)?.request);
      return scope.setTag('handler', 'axios.interceptors.errorHandler');
    });
  }

  if (e.response) {
    const { status, data } = e.response;

    /**
     * The request was made and the server responded with a status code that falls out of the range of 2xx
     */
    if (status === UNAUTHORIZED_ERROR_CODE) {
      /**
       * CASE 1: All 401 errors will be reported to be handled by the application.
       */
      EventEmitter.publish(BAD_REQUEST_EVENT, status);
    }

    if (
      !isApiBusinessLogicError(data) &&
      (status === FORBIDDEN_ERROR_CODE || status === NOT_FOUND_ERROR_CODE)
    ) {
      // CASE 2: The rest of the errors will be reported to be handled by the application only if it's not a business logic API error
      EventEmitter.publish(BAD_REQUEST_EVENT, status);
    }
  }

  throw e;
};

axiosInstance.interceptors.response.use(undefined, errorHandler);

axiosInstance.interceptors.request.use((config) => {
  /**
   * Whatever API needs to receive this header needs to explicitly allow it. and will throw errors on CORS if not allowed.
   * so we only add the header if the request is going to our own API.
   */
  if (API_HOST && config.url?.startsWith(API_HOST)) {
    config.headers['X-Trace-Id'] = 'qb-' + crypto.randomUUID();
  }

  if (isServer) {
    config.headers['User-Agent'] = 'Axios/Quotebeam';
  }

  return config;
}, errorHandler);
