'use client';

import { useState } from 'react';
import { StaticImageData } from 'next/dist/shared/lib/get-img-props';
import { ImageProps } from 'next/image';
import { SxProps } from '@mui/material';
import { PreserveAspectRatioImage } from '@qb/frontend/components/PreserveAspectRatioImage';
import noBrandBox from '@qb/frontend/icons/no-brand-box.png';
import { getS3FileUrl } from '@qb/frontend/utils/getS3FileUrl';
import { getConfigFromTenantAlias, TenantAlias } from '@/shared/tenants-alias-map';
type ImageWithFallbackProps = Omit<ImageProps, 'src'> & {
  tenantAlias: TenantAlias;
  src: ImageProps['src'] | undefined;
  sx?: SxProps;
  bgColorFill?: string;
};
export const ImageWithFallback = ({
  src,
  tenantAlias,
  bgColorFill,
  width,
  height,
  ...restImageProps
}: ImageWithFallbackProps) => {
  if (!!src) {
    return <PreserveAspectRatioImage src={src} width={width} height={height} bgColorFill={bgColorFill} {...restImageProps} />;
  }
  return <ImageFallback tenantAlias={tenantAlias} width={width} height={height} bgColorFill={bgColorFill} {...restImageProps} data-sentry-element="ImageFallback" data-sentry-component="ImageWithFallback" data-sentry-source-file="ImageWithFallback.tsx" />;
};
const ImageFallback = ({
  tenantAlias,
  bgColorFill,
  width,
  height,
  ...restImageProps
}: Omit<ImageWithFallbackProps, 'src'>) => {
  const {
    tenantID
  } = getConfigFromTenantAlias(tenantAlias);

  // example image on s3: "https://appstoragepublicstaging.s3.us-west-1.amazonaws.com/SPSStatic/18/box-advantageelectricsupply.png"
  const missingImageTarget = getS3FileUrl(process.env.AWS_S3_PUBLIC_BUCKET || '', `SPSStatic/${tenantID}`, `box-${tenantAlias}.png`);
  const [hasError, setHasError] = useState(false);

  /**
   * Vite and Next.js have different ways of handling image imports.
   * Vite: (string) `/www/packages/frontend/icons/no-brand-box.png`
   * Next.js: (object) `{ src : "/_next/static/media/no-brand-box.ebad2d6f.png" }`
   */
  const crossAppsSrc: string = typeof noBrandBox === 'string' ? noBrandBox : (noBrandBox as StaticImageData)?.src;
  return <PreserveAspectRatioImage src={hasError ? crossAppsSrc : missingImageTarget} unoptimized={hasError} width={width} height={height} bgColorFill={bgColorFill} {...restImageProps} onError={() => setHasError(true)} data-sentry-element="PreserveAspectRatioImage" data-sentry-component="ImageFallback" data-sentry-source-file="ImageWithFallback.tsx" />;
};