import { useEffect, useCallback } from 'react';
import { MessagesFromSP } from '@/shared/SalesPilotConstants';
import { UserInfo } from '@/shared/types/controllers/AuthControllerTypes';
import { useWidgetPartsPrices, PriceAndOriginalPrice, PartsMap } from './WidgetPartsPricesProvider';
type MultiplePartsPricesResponseEventPayload = {
  action: typeof MessagesFromSP.MULTIPLE_PARTS_PRICE_RESPONSE;
  payload: {
    authUser: UserInfo | null;
    pricesAndOriginalPrices: Array<PriceAndOriginalPrice & {
      partID: number;
    }>;
  };
};
const changePartsMapStatusToQueue = (partIDs: number[], parts: PartsMap) => {
  return partIDs.reduce<PartsMap>((acc, partID) => {
    const part = parts[partID];
    if (!part) {
      acc[partID] = {
        status: 'queued'
      };
    }
    return acc;
  }, parts);
};
const changeQueuedIDsToPendingInPartsMap = (partIDs: number[], parts: PartsMap) => {
  return partIDs.reduce<PartsMap>((acc, partID) => {
    if (parts[partID]?.status === 'queued') {
      acc[partID] = {
        status: 'pending'
      };
    }
    return acc;
  }, parts);
};
const getQueuedPartsIDs = (parts: PartsMap): number[] => {
  return Object.entries(parts).filter(([_key, value]) => value.status === 'queued').map(([key]) => Number(key));
};

/**
 * SPS doesn't have access to the user auth state directly, so we can't show discounted prices based on the user's info.
 * How does this hook works?
 * 1. SPS page loads in the browser and immediately requests the part price from SPW.
 * 2. The request will be queued since the iframe is likely not loaded yet.
 * 3. Once the iframe is loaded, the queued requests will be processed and the prices responses will start coming in.
 * causing the components to re-render with the new prices/discounts.
 * 4. If the user signs-in or signs-out, the iframe will reload and the queued requests will be processed again to fetch the prices based on the new user or anonymous user.
 */
export const useRequestPartPriceFromSPW = () => {
  const {
    parts,
    setParts,
    authUser,
    setAuthUser,
    isIframeLoaded,
    setIsIframeLoaded
  } = useWidgetPartsPrices();

  // Event Listener for Iframe Loaded
  useEffect(() => {
    const eventListener = (e: MessageEvent) => {
      const action = e.data.action;
      switch (action) {
        case MessagesFromSP.IFRAME_LOADED:
          setIsIframeLoaded(true);
          break;
        case MessagesFromSP.MULTIPLE_PARTS_PRICE_RESPONSE:
          {
            const {
              payload: {
                pricesAndOriginalPrices,
                authUser: newAuthUser
              }
            } = (e.data as MultiplePartsPricesResponseEventPayload);
            const newPartsMap = pricesAndOriginalPrices.reduce<PartsMap>((acc, {
              partID,
              ...data
            }) => {
              acc[Number(partID)] = {
                status: 'success',
                data
              };
              return acc;
            }, parts);
            setParts(newPartsMap);
            setAuthUser(newAuthUser);
          }
          break;
        case MessagesFromSP.USER_LOGGED_IN:
        case MessagesFromSP.USER_SIGNED_UP:
        case MessagesFromSP.USER_LOGGED_OUT:
          {
            setParts({});
            setAuthUser(null);
            setIsIframeLoaded(false);
          }
          break;
        default:
          break;
      }
    };
    window.addEventListener('message', eventListener);
    return () => window.removeEventListener('message', eventListener);
  }, [setParts, setAuthUser, setIsIframeLoaded, parts]);

  // Process queued part price requests when iframe is loaded
  useEffect(() => {
    if (isIframeLoaded) {
      const partIDs = getQueuedPartsIDs(parts);
      window.salesPilotPostMessages?.requestMultiplePartsPrices?.({
        partIDs
      });
      const partsWithPendingStatus = changeQueuedIDsToPendingInPartsMap(partIDs, parts);
      setParts(partsWithPendingStatus);
    }
  }, [isIframeLoaded, parts, setParts]);
  const requestMultiplePartsPrices = useCallback((partIDs: number[]) => {
    setParts(prevPartsState => changePartsMapStatusToQueue(partIDs, prevPartsState));
  }, [setParts]);
  const getPartPrice = useCallback((partID: number) => parts[partID]?.data, [parts]);
  return {
    requestMultiplePartsPrices,
    getPartPrice,
    authUser,
    isIframeLoaded
  };
};