import { range } from 'lodash';
import { Box, Stack, Typography } from '@mui/material';
import { MascotAnimation } from '@qb/frontend/components/OmniSearch/_components/OmniSearchDesktopPopup/components/MascotAnimation/MascotAnimation';
import { ViewAllCategoriesButton } from '@qb/frontend/components/OmniSearch/_components/ViewAllCategoriesButton';
import { usePartCategoriesAutocompleteRequest } from '@qb/frontend/components/OmniSearch/_hooks/usePartCategoriesAutocompleteRequest';
import { TenantAliasProps } from '@qb/frontend/types/tenantAlias.types';
import { isNullableOrEmptyArray } from '@/shared/types/util/TypeGuards';
import { PartCategoryResultSkeleton, PartCategoryResult } from './PartCategoryResult';
type PartCategoryQueryProps = TenantAliasProps & {
  query: string;
  isUserTyping: boolean;
};
export const PartCategoryQuery = ({
  query,
  isUserTyping,
  tenantAlias
}: PartCategoryQueryProps) => {
  const {
    partCategories,
    arePartCategoriesLoading
  } = usePartCategoriesAutocompleteRequest(query, tenantAlias);
  if (arePartCategoriesLoading) {
    return <Stack direction="column" spacing={1}>
        <MascotAnimation tenantAlias={tenantAlias} variant="matrix" sx={{
        alignSelf: 'flex-end'
      }} />
        <Typography variant="h6" color="text.secondary" pb={1}>
          Product categories
        </Typography>
        {range(3).map(index => <PartCategoryResultSkeleton key={index} />)}
        <Box pl={2}>
          <ViewAllCategoriesButton />
        </Box>
      </Stack>;
  }
  if (isNullableOrEmptyArray(partCategories)) {
    return <Stack direction="column" alignItems="flex-end" spacing={4}>
        <MascotAnimation tenantAlias={tenantAlias} variant={isUserTyping ? 'matrix' : 'monocle'} smoothSwitch />
        <ViewAllCategoriesButton />
      </Stack>;
  }
  return <Stack direction="column" spacing={1} data-sentry-element="Stack" data-sentry-component="PartCategoryQuery" data-sentry-source-file="PartCategoryQuery.tsx">
      <MascotAnimation tenantAlias={tenantAlias} variant={isUserTyping ? 'matrix' : 'monocle'} smoothSwitch sx={{
      alignSelf: 'flex-end'
    }} data-sentry-element="MascotAnimation" data-sentry-source-file="PartCategoryQuery.tsx" />
      <Typography variant="h6" color="text.secondary" pb={1} data-sentry-element="Typography" data-sentry-source-file="PartCategoryQuery.tsx">
        Product categories
      </Typography>
      <Stack direction="column" spacing={2} pl={2} data-sentry-element="Stack" data-sentry-source-file="PartCategoryQuery.tsx">
        {partCategories.map(partCategory => <PartCategoryResult tenantAlias={tenantAlias} key={partCategory.id} partCategory={partCategory} />)}
      </Stack>
      <Box pl={2} data-sentry-element="Box" data-sentry-source-file="PartCategoryQuery.tsx">
        <ViewAllCategoriesButton data-sentry-element="ViewAllCategoriesButton" data-sentry-source-file="PartCategoryQuery.tsx" />
      </Box>
    </Stack>;
};