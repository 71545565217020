/**
 * localStorage with error handling for when the `localStorage` is disabled on the device.
 * @see https://www.chromium.org/for-testers/bug-reporting-guidelines/uncaught-securityerror-failed-to-read-the-localstorage-property-from-window-access-is-denied-for-this-document/
 */
export const LocalStorage = {
  getItem(key: string, fallbackValue: string | null = null): string | null {
    try {
      return localStorage.getItem(key);
    } catch (e) {
      if ((e as DOMException).name !== 'SecurityError') {
        throw e;
      }
      return fallbackValue;
    }
  },

  setItem(key: string, value: string): void {
    try {
      localStorage.setItem(key, value);
    } catch (e) {
      if ((e as DOMException).name !== 'SecurityError') {
        throw e;
      }
    }
  },
  removeItem(key: string): void {
    try {
      localStorage.removeItem(key);
    } catch (e) {
      if ((e as DOMException).name !== 'SecurityError') {
        throw e;
      }
    }
  },
};
