'use client';

import { FocusEvent, KeyboardEvent, useCallback } from 'react';
import { ChevronLeft } from '@mui/icons-material';
import CancelIcon from '@mui/icons-material/Cancel';
import { InputAdornment, FilledInput, OutlinedInputProps, IconButton } from '@mui/material';
import { recordEvent } from '@qb/frontend/analytics/ga4';
import { OmniSearchInputG4AEventsProps } from '@qb/frontend/components/OmniSearch/_components/OmniSearchInput';
import { mergeSx } from '@qb/frontend/utils/mergeSx';
import { IconMagic } from '../../IconMagic';
export type SearchDrawerInputProps = OmniSearchInputG4AEventsProps & {
  onClear: () => void;
  onClose: () => void;
  value: string;
  handlerRedirect: (href: string) => void;
} & Pick<OutlinedInputProps, 'onChange' | 'sx'>;
export const SearchDrawerInput = ({
  value,
  onChange,
  onClear,
  onClose,
  searchInputName,
  handlerRedirect,
  sx
}: SearchDrawerInputProps) => {
  const onFocusHandler = useCallback((event: FocusEvent<HTMLInputElement>) => {
    if (searchInputName) {
      const query = event.currentTarget.value;
      recordEvent('search_focus', {
        attributes: {
          search_phrase: query,
          search_type: 'freeform',
          search_inputbox_name: searchInputName
        }
      });
    }
  }, [searchInputName]);
  const onKeyDownHandler = useCallback((event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && value) {
      event.currentTarget.blur();
      handlerRedirect(`/search?query=${value}`);
    }
    if (event.key === 'Escape') {
      onClose();
    }
  }, [handlerRedirect, onClose, value]);
  return <FilledInput autoFocus value={value} placeholder="Search by part, # or keyword" startAdornment={<InputAdornment position="start" sx={{
    ml: -1
  }} onClick={onClose}>
          <IconButton>
            <ChevronLeft />
          </IconButton>
        </InputAdornment>} endAdornment={value ? <InputAdornment position="end" sx={{
    mr: 1
  }} onClick={onClear}>
            <IconButton>
              <CancelIcon />
            </IconButton>
          </InputAdornment> : <IconMagic sx={{
    width: 65
  }} />} inputProps={{
    sx: {
      pl: 1,
      py: 0.75,
      '&::placeholder': {
        color: 'text.secondary'
      }
    },
    type: 'search',
    enterKeyHint: 'search'
  }} sx={mergeSx({
    width: '100%',
    height: 50,
    '&.MuiFilledInput-root': {
      pr: 0
    }
  }, sx)} onKeyDown={onKeyDownHandler} onFocus={onFocusHandler} onChange={onChange} data-sentry-element="FilledInput" data-sentry-component="SearchDrawerInput" data-sentry-source-file="SearchDrawerInput.tsx" />;
};