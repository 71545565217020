export function isString(x: any): x is string {
  return typeof x === 'string';
}

export function isNotEmpty<T>(data: T): data is NonNullable<T> {
  return data !== null && typeof data !== 'undefined';
}

export function isEmpty<T>(
  data: T | null | undefined,
): data is null | undefined {
  return data === null || typeof data === 'undefined';
}

export function isNumber(x: any): x is number {
  return typeof x === 'number';
}

type NonEmptyArray<T> = [T, ...T[]];
export const isNonEmptyArray = <T>(
  arr: T[] | undefined | null,
): arr is NonEmptyArray<T> => Array.isArray(arr) && arr.length > 0;

export const isNullableOrEmptyArray = <T>(
  arr: T[] | undefined | null,
): arr is [] | undefined | null =>
  isEmpty(arr) || (Array.isArray(arr) && arr.length === 0);
