import { Box, Card, Stack } from '@mui/material';
import { PartCategoryQuery } from '@qb/frontend/components/OmniSearch/_components/OmniSearchDesktopPopup/components/Categories/PartCategoryQuery';
import { ManufacturerQuery } from '@qb/frontend/components/OmniSearch/_components/OmniSearchDesktopPopup/components/Manufacturers/ManufacurerQuery';
import { PartQuery } from '@qb/frontend/components/OmniSearch/_components/OmniSearchDesktopPopup/components/Parts/PartQuery';
import { TenantAliasProps } from '@qb/frontend/types/tenantAlias.types';
type QueryProps = TenantAliasProps & {
  query: string;
  isUserTyping: boolean;
};
export const Query = ({
  query,
  isUserTyping,
  tenantAlias
}: QueryProps) => <Card data-sentry-element="Card" data-sentry-component="Query" data-sentry-source-file="Query.tsx">
    <Stack direction="row" sx={{
    alignItems: 'stretch'
  }} data-sentry-element="Stack" data-sentry-source-file="Query.tsx">
      <Box sx={{
      bgcolor: 'primary.4p',
      flex: 1,
      py: 2,
      px: 4
    }} data-sentry-element="Box" data-sentry-source-file="Query.tsx">
        <PartQuery query={query} tenantAlias={tenantAlias} data-sentry-element="PartQuery" data-sentry-source-file="Query.tsx" />
      </Box>
      <Stack direction="column" spacing={2} sx={{
      width: 235,
      py: 2,
      px: 2
    }} data-sentry-element="Stack" data-sentry-source-file="Query.tsx">
        <PartCategoryQuery query={query} isUserTyping={isUserTyping} tenantAlias={tenantAlias} data-sentry-element="PartCategoryQuery" data-sentry-source-file="Query.tsx" />
        <ManufacturerQuery query={query} tenantAlias={tenantAlias} data-sentry-element="ManufacturerQuery" data-sentry-source-file="Query.tsx" />
      </Stack>
    </Stack>
  </Card>;