'use client';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Button, SxProps } from '@mui/material';
type ViewAllCategoriesButtonProps = {
  content?: string;
  sx?: SxProps;
  textDecoration?: boolean;
  onClick?: () => void;
};
export const ViewAllCategoriesButton = ({
  content,
  onClick,
  sx,
  textDecoration = false
}: ViewAllCategoriesButtonProps) => {
  const href = '/search/category';
  const textDecorationSx = textDecoration ? {} : {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none'
    }
  };
  return <Button size="small" variant="text" href={href} sx={{
    fontSize: 14,
    fontWeight: 600,
    ...textDecorationSx,
    ...sx
  }} endIcon={<ArrowForwardIcon />} onClick={onClick} data-sentry-element="Button" data-sentry-component="ViewAllCategoriesButton" data-sentry-source-file="ViewAllCategoriesButton.tsx">
      {content ?? 'View all Categories'}
    </Button>;
};