'use client';

import { Button, SxProps } from '@mui/material';
type ViewAllPartsButtonProps = {
  query: string;
  onClick?: () => void;
  sx?: SxProps;
};
export const ViewAllPartsButton = ({
  query,
  onClick,
  sx
}: ViewAllPartsButtonProps) => {
  let href = '/search';
  if (query) {
    href += `?query=${query}`;
  }
  return <Button variant="contained" href={href} onClick={onClick} fullWidth sx={sx} data-sentry-element="Button" data-sentry-component="ViewAllPartsButton" data-sentry-source-file="ViewAllPartsButton.tsx">
      View all parts
    </Button>;
};