'use client';

import { Box, Button, Stack, Typography, List, ListItem } from '@mui/material';
import { TenantAliasProps } from '@qb/frontend/types/tenantAlias.types';
import { ReactComponent as ThreeDots } from '../../assets/three-dots.svg';
export const NoPartMessage = ({
  tenantAlias
}: TenantAliasProps) => {
  if (tenantAlias === 'demo') {
    return <Stack spacing={2}>
        <Typography variant="h6" component="p">
          {`Don't see the part you're looking for?`}
        </Typography>
        <Typography variant="h6" component="p">
          This demo environment has a limited number of products available for
          testing.
        </Typography>
        <Typography variant="h6" component="p">
          Why don’t you try:
        </Typography>
        <List sx={{
        listStyleType: 'disc',
        pl: 2,
        py: 0,
        '& .MuiListItem-root': {
          display: 'list-item',
          p: 0
        }
      }}>
          <ListItem>
            <Typography variant="h6" component="p">
              SU203ML-K15
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="h6" component="p">
              541344
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="h6" component="p">
              QS10.241
            </Typography>
          </ListItem>
        </List>
        <Typography variant="h6" component="p">
          {`If this happens on your real site, we'll encourage customers to chat with
      you.`}
        </Typography>
      </Stack>;
  }
  return <Typography variant="h6" component="p" data-sentry-element="Typography" data-sentry-component="NoPartMessage" data-sentry-source-file="NoPartResults.tsx">
      {`Don't see the part you're looking for? Write to us and we will help you
      find it.`}
    </Typography>;
};
export const NoPartResults = ({
  tenantAlias
}: TenantAliasProps) => {
  return <Stack sx={{
    height: '100%',
    alignItems: 'flex-end',
    justifyContent: 'flex-end'
  }} data-sentry-element="Stack" data-sentry-component="NoPartResults" data-sentry-source-file="NoPartResults.tsx">
      <Box sx={{
      width: 330,
      minHeight: 100,
      bgcolor: 'primary.8p',
      borderRadius: 2,
      p: 2,
      mb: 2
    }} data-sentry-element="Box" data-sentry-source-file="NoPartResults.tsx">
        <NoPartMessage tenantAlias={tenantAlias} data-sentry-element="NoPartMessage" data-sentry-source-file="NoPartResults.tsx" />
      </Box>
      <ThreeDots data-sentry-element="ThreeDots" data-sentry-source-file="NoPartResults.tsx" />
      <Button variant="contained" color="primary" fullWidth onClick={e => {
      e.preventDefault();
      window?.salesPilotPostMessages?.openChatPage();
    }} sx={{
      mt: 4
    }} data-sentry-element="Button" data-sentry-source-file="NoPartResults.tsx">
        Chat with us
      </Button>
    </Stack>;
};