import { Box, SxProps } from '@mui/material';
import { TenantAliasProps } from '@qb/frontend/types/tenantAlias.types';
import { mergeSx } from '@qb/frontend/utils/mergeSx';
import { MascotAnimationFriendly } from './components/MascotAnimationFriendly';
import { MascotAnimationMatrix } from './components/MascotAnimationMatrix';
import { MascotAnimationMonocle } from './components/MascotAnimationMonocle';
const animations = {
  matrix: MascotAnimationMatrix,
  monocle: MascotAnimationMonocle,
  friendly: MascotAnimationFriendly
};
type MascotAnimationProps = {
  variant: 'matrix' | 'monocle' | 'friendly';
  /* Renders all versions and switches using display css property. Prevents flicker
   * that happens when used with conditional rendering*/
  smoothSwitch?: boolean;
  sx?: SxProps;
};
export const MascotAnimationInternal = ({
  variant,
  smoothSwitch = false
}: MascotAnimationProps) => {
  if (smoothSwitch) {
    return <>
        {Object.entries(animations).map(([key, Component]) => <Box key={key} display={key === variant ? 'block' : 'none'}>
            <Component />
          </Box>)}
      </>;
  }
  const AnimationComponent = animations[variant];
  return <AnimationComponent data-sentry-element="AnimationComponent" data-sentry-component="MascotAnimationInternal" data-sentry-source-file="MascotAnimation.tsx" />;
};
export const MascotAnimation = ({
  tenantAlias,
  sx = {},
  ...props
}: TenantAliasProps & MascotAnimationProps) => {
  const boxSx = mergeSx({
    width: 130,
    height: 180
  }, sx);
  if (tenantAlias === 'demo') {
    return <Box sx={boxSx}>
        <MascotAnimationInternal {...props} />
      </Box>;
  }
  return <Box sx={boxSx} data-sentry-element="Box" data-sentry-component="MascotAnimation" data-sentry-source-file="MascotAnimation.tsx">
      <MascotAnimationFriendly autoplay={false} data-sentry-element="MascotAnimationFriendly" data-sentry-source-file="MascotAnimation.tsx" />
    </Box>;
};