'use client';

import { useCallback } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Card, CardActionArea, Stack, Typography } from '@mui/material';
import { ImageWithFallback } from '@qb/frontend/components/ImageWithFallback';
import { clampLinesSx } from '@qb/frontend/utils/clampLinesSx';
import { TenantAlias } from '@/shared/tenants-alias-map';
import { generatePartURL } from '@/shared/utils/URLs';
type PartCardSmallProps = {
  part: {
    id: number;
    partNumber: string;
    description: string;
    images: {
      target: string;
    }[];
    manufacturer: {
      name: string;
    };
    onHandQty: number | undefined;
  };
  onClick?: () => void;
  tenantAlias: TenantAlias;
};
export const PartCardSmall = ({
  part,
  onClick,
  tenantAlias
}: PartCardSmallProps) => {
  const onHandQty = part.onHandQty ?? 0;
  const href = generatePartURL(part.manufacturer.name, part.partNumber, part.id);
  const onPartView = useCallback(() => {
    if (onClick) {
      onClick();
    }
  }, [onClick]);
  return <Card sx={{
    borderWidth: '2px',
    borderColor: 'primary.50p',
    mb: {
      xs: 1,
      borderRadius: 0
    }
  }} data-sentry-element="Card" data-sentry-component="PartCardSmall" data-sentry-source-file="PartCardSmall.tsx">
      <CardActionArea onClick={onPartView} href={href} sx={{
      pl: 1.5,
      pr: 2,
      py: 1
    }} data-sentry-element="CardActionArea" data-sentry-source-file="PartCardSmall.tsx">
        <Stack direction="row" spacing={2} sx={{
        minWidth: 200
      }} data-sentry-element="Stack" data-sentry-source-file="PartCardSmall.tsx">
          <ImageWithFallback tenantAlias={tenantAlias} src={part.images[0]?.target} width={50} height={50} alt={part.partNumber} data-sentry-element="ImageWithFallback" data-sentry-source-file="PartCardSmall.tsx" />
          <Stack direction="column" sx={{
          width: '100%',
          overflow: 'hidden'
        }} data-sentry-element="Stack" data-sentry-source-file="PartCardSmall.tsx">
            <Typography variant="h6" component="h3" mb={0.5} data-sentry-element="Typography" data-sentry-source-file="PartCardSmall.tsx">
              {part.partNumber}
            </Typography>
            <Typography title={part.description} variant="bodyMedium" sx={{
            mb: 1,
            height: 67,
            ...clampLinesSx(4)
          }} data-sentry-element="Typography" data-sentry-source-file="PartCardSmall.tsx">
              {part.description}
            </Typography>
            <Stack mt="auto" direction="row" justifyContent="space-between" data-sentry-element="Stack" data-sentry-source-file="PartCardSmall.tsx">
              <Typography variant="bodyMediumPrimary" color="text.primary" noWrap data-sentry-element="Typography" data-sentry-source-file="PartCardSmall.tsx">
                {part.manufacturer.name}
              </Typography>
              {onHandQty > 0 && <Typography variant="h6" component="p" sx={{
              display: 'flex',
              whiteSpace: 'nowrap'
            }} title={`Stock: ${onHandQty}`}>
                  <CheckCircleIcon sx={{
                mr: 0.5,
                fontSize: 18
              }} color="success" />
                  {onHandQty}
                </Typography>}
            </Stack>
          </Stack>
        </Stack>
      </CardActionArea>
    </Card>;
};