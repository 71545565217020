import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { Box, SxProps } from '@mui/material';
type Props = {
  sx?: SxProps;
};
export const IconMagic = ({
  sx
}: Props) => {
  return <Box sx={{
    bgcolor: 'primary.main',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'stretch',
    fontSize: 24,
    ...sx
  }} data-sentry-element="Box" data-sentry-component="IconMagic" data-sentry-source-file="IconMagic.tsx">
      <AutoAwesomeIcon htmlColor="white" fontSize="inherit" data-sentry-element="AutoAwesomeIcon" data-sentry-source-file="IconMagic.tsx" />
    </Box>;
};