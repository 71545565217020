import { useEffect, useState } from 'react';
import { useDebounce } from 'react-use';

export const useIsUserTyping = (query: string, delay = 2000) => {
  const [isUserTyping, setIsUserTyping] = useState(false);

  useDebounce(() => setIsUserTyping(false), delay, [query]);

  useEffect(() => {
    if (query) {
      setIsUserTyping(true);
    }
  }, [query]);

  return isUserTyping;
};
