const encodeWithSlashReplacement = (str: string) => {
  let replacedStr = str.split(' ').join('-');
  replacedStr = replacedStr.split('/').join('-');
  return encodeURIComponent(replacedStr);
};

/**
 * Merge all contiguous non-alphanumberic characters into a dash
 * Cases:
 * a b       -> a-b
 * a, b      -> a-b
 * a - b     -> a-b
 * a, b, & c -> a-b-c
 */
export const nonAlphaNumCharReplacement = (str: string) => {
  return str.replace(/[^a-zA-Z0-9]+/g, '-');
};

/**
 * pagination will not use the `page` param for the first page... but will use it for the subsequent pages.
 */
export const appendPageParam = (pageNumber: number | null) => {
  if (pageNumber === 1 || pageNumber === null) return '';
  return `?page=${pageNumber}`;
};

/**
 * Adds dash for SEO
 */
export const generatePartURL = (
  manufacturerName: string,
  partNumber: string,
  partID: number,
) => {
  return `/part/${encodeWithSlashReplacement(
    manufacturerName.toLowerCase(),
  )}/${encodeWithSlashReplacement(partNumber.toLowerCase())}/${partID}`;
};

/**
 * Adds dash for SEO
 * @param pageNumber - Adds a page number query param to the URL to navigate to a specific page of the category
 */
export const generatePartCategorySearchURL = (
  partCategoryName: string,
  partCategoryID: number,
  pageNumber = 1,
) => {
  return `/search/partCategory/${nonAlphaNumCharReplacement(
    partCategoryName.toLowerCase(),
  )}/${partCategoryID}${appendPageParam(pageNumber)}`;
};

/**
 * Adds dash for SEO
 * @param pageNumber - Adds a page number query param to the URL to navigate to a specific page of the category
 */
export const generateSPSPartCategorySearchURL = (
  partCategoryName: string,
  partCategoryID: number,
  pageNumber = 1,
) => {
  return `/search/category/${nonAlphaNumCharReplacement(
    partCategoryName.toLowerCase(),
  )}/${partCategoryID}${appendPageParam(pageNumber)}`;
};

// Re-exporting again as default so we don't have to rewrite all the imports across the entire app.
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  generatePartCategorySearchURL,
  generatePartURL,
  appendPageParam,
};
