import { nonAlphaNumCharReplacement } from '@/shared/utils/URLs';

type Category = {
  id: number;
  name: string;
};

export const getSPSCategoryURL = (category: Category) => {
  return `/search/category/${nonAlphaNumCharReplacement(
    category.name.toLowerCase(),
  )}/${category.id}`;
};

type Manufacturer = {
  id: number;
  name: string;
};
export const getSPSManufacturerURL = (manufacturer: Manufacturer) => {
  return `/search/manufacturer/${nonAlphaNumCharReplacement(
    manufacturer.name.toLowerCase(),
  )}/${manufacturer.id}`;
};

type CategoryManufacturer = {
  category: Category;
  manufacturer: Manufacturer;
};
export const getSPSCategoryManufacturerURL = ({
  category,
  manufacturer,
}: CategoryManufacturer) => {
  return `/search/cm/${nonAlphaNumCharReplacement(
    category.name.toLowerCase(),
  )}/${category.id}/${nonAlphaNumCharReplacement(
    manufacturer.name.toLowerCase(),
  )}/${manufacturer.id}`;
};
